import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FeatherIcon from "feather-icons-react";
// import LeconCard from "../components/LeconCard";
import { NavLink, Link } from "react-router-dom";
import baseUrl from "../../http/backend-base-url";
import DetailSessionSkeleton from "../components/DetailSessionSkeleton";
import ItemSessionDetailsPage from "../components/ItemSessionDetailsPage";
import MessageAllPage from "../../backend/components/MessageAllPage";

function SessionDetailsV2() {
  const [isDoing, setIsDoing] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [module, setModule] = useState({});
  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let userID = window.localStorage.getItem("userID");
  const params = useParams();
  let { id } = params;

  const getModuleItem = async (id) => {
    const url = `${api}module/${id}`;
    await axios
      .get(url, config)
      .then((response) => {
        //console.log("response", response.data);
        setModule(response.data);
        setIsLoading(false);

        // userModules();
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("e", e);
      });
  };

  const getUserModules = async (id) => {
    let checker = (arr) =>
      arr.every((v) => parseInt(v?.module?.id) !== parseInt(id));
    const url = api + "usermodules/" + parseInt(userID) + "/";
    await axios
      .get(url, config)
      .then((data) => {
        //console.log("module user", checker(data.data.data), this.state?.moduleId);
        setIsDoing(checker(data.data.data));
        // if (checker(data.data.data) !== false) {
        //   this.setState({ isDoing: false });
        // } else {
        //   this.setState({ isDoing: true });
        // }
        this.setState({ usermodules: data.data.data });
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  };

  const getSessionByModule = async (id) => {
    const url = `${api}sessionByModule/${id}`;
    try {
      const res = await axios.get(url, config);
      if (res["status"] === 201 || res["status"] === 200) {
        // this.setState({ session: res?.data?.sort((a, b) => a?.id - b?.id) });
        setSessions(res?.data);
        console.log(res["data"]);
      }
    } catch (error) {
      console.log(error.message);
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (id) {
      getModuleItem(id);
      getSessionByModule(id);
      getUserModules(id);
    }
  }, [id]);

  const userDoModule = async () => {
    if (!isDoing) {
      await axios
        .post(
          api + "userdomodule/",
          {
            progression: 0,
            user: parseInt(userID),
            module: parseInt(id),
          },
          config
        )
        .then((data) => {
          console.log("data", data.data);
        })
        .catch((e) => {
          console.log("e", e.response);
        });
    }
    window.scrollTo(0, 0);
  };

  //   const getUsersContinu = async () => {
  //     let url = api + `usercontenus/${userID}/`;
  //     try {
  //       let req = await axios.get(url, config);
  //       // console.log(req);
  //       let data = req["data"];
  //       // eslint-disable-next-line no-unused-vars
  //       let filterModule = data
  //         .map((item) => item.contenu)
  //         .filter((c) => c.module === this.state.moduleId);
  //       //console.log(filterModule);
  //     } catch (error) {
  //       console.log(error.response);
  //     }
  //   };

  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-3 col-md-3 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>
        {isLoading ? (
          <DetailSessionSkeleton />
        ) : (
          <div className="col-xl-10 col-lg-9 col-lg-9 col-md-12 content-position-after-header p-b-200">
            <div className="">
              <div className="card">
                <div className="banner-image-cours-details">
                  <div className="row">
                    <div className="col-md-7">
                      {/* <p className="f-f-work text-dark ">Module 1 :</p> */}
                      <p className="text-banner-image-cours-details">
                        {module?.name}
                      </p>

                      <div className="d-flex">
                        <FeatherIcon
                          className="icon-details-infos-cours"
                          icon="align-justify"
                        />
                        <p className="text-details-infos-cours">
                          <span className="fw-600">
                            {module?.number_session} Sessions
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 offset-md-1 itm-center">
                      <img
                        className="w-full"
                        src={
                          module?.logo
                            ? baseUrl + module?.logo
                            : "/img/intersect.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="content-cours-details">
                  <div className="row">
                    <div className="col-md-10">
                      {/* <p className="text-dark m-b-20 fs-14 fw-600 f-f-work">
                          Bonjour et bienvenue au Module :&nbsp;<b>{module.name}</b>
                        </p> */}
                      <div className="text-black m-b-100 fs-14 fw-300">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: module?.description,
                          }}
                        />
                      </div>
                      <div className="row m-b-30 dis-flex">
                        <div className="col m-b-20">
                          <NavLink
                            onClick={this.userDoModule}
                            className="btn-theme text-up"
                            to={`/session-start/${"module"}/${module?.id}`}
                          >
                            {this.state.isDoing === true
                              ? "Continuer le module"
                              : "Commencer le module"}
                          </NavLink>
                        </div>
                        {/* <div className="col-xl-4 col-lg-5 col-md-5 ml-auto">
                            <a className="btn-theme-light" href=""><FeatherIcon className="icon-button" icon="download" />Télécharger le cours</a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-session-details-page">
                <h6 className="text-up text-dark f-f-work fw-600">
                  <NavLink
                    className="link-details-sessions-on-title"
                    to={{
                      pathname: `/session-start/${"module"}/${module.id}`,
                      state: { type: "introduction", item: module },
                    }}
                  >
                    Introduction
                  </NavLink>
                </h6>
                <NavLink
                  className="link-show-on-item-session-details-page"
                  //   to={`/session-start/${"module"}/${module.id}`}
                  to={{
                    pathname: `/session-start/${"module"}/${module.id}`,
                    state: { type: "introduction", item: module },
                  }}
                >
                  <FeatherIcon
                    className="icon-show-on-item-session-details-page"
                    icon="chevron-right"
                  />
                </NavLink>
              </div>

              <p className="text-up text-black m-b-20 fs-12 fw-600 f-f-work">
                Toutes les sessions
              </p>

              {sessions?.length
                ? sessions?.map((session) => (
                    <SessionItem item={session} isDoing={isDoing} />
                  ))
                : ""}

              {/* <ItemSessionDetailsPage isDoing={isDoing} moduleId={id} /> */}

              <div className="dis-flex m-t-50">
                <NavLink
                  onClick={() => userDoModule()}
                  className="btn-theme text-up"
                  to={`/session-start/${"module"}/${id}`}
                >
                  {isDoing === true
                    ? "Continuer le module"
                    : "Commencer le module"}
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default SessionDetailsV2;

const SessionItem = (item, isDoing) => {
  return (
    <div className="item-session-details-page" key={item.id}>
      {isDoing === true ? (
        <>
          <h6 className="text-up text-dark f-f-work fw-600">
            <Link
              className="link-details-sessions-on-title"
              // to={`/session-start/${"session"}/${item.id}`}
              to={{
                pathname: `/session-start/${"session"}/${item.id}`,
                state: { type: "session", item: item },
                hash: "session",
              }}
            >
              {item.name}
            </Link>
          </h6>
          <NavLink
            className="link-show-on-item-session-details-page"
            //   to={`/session-start/${"session"}/${item.id}`}
            to={{
              pathname: `/session-start/${"session"}/${item.id}`,
              state: { type: "session", item: item },
              hash: "teste",
            }}
          >
            <FeatherIcon
              className="icon-show-on-item-session-details-page"
              icon="chevron-right"
            />
          </NavLink>
        </>
      ) : (
        <>
          <h6 className="text-up text-dark f-f-work fw-600">
            <NavLink className="link-details-sessions-on-title" to="#">
              {item.name}
            </NavLink>
          </h6>
          <NavLink className="link-show-on-item-session-details-page" to="#">
            <FeatherIcon
              className="icon-show-on-item-session-details-page"
              icon="chevron-right"
            />
          </NavLink>
        </>
      )}
    </div>
  );
};
