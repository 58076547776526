import { SCORM, debug } from 'pipwerks-scorm-api-wrapper';
class ScormAPI {
    constructor() {
        this.state = {
            apiConnected: false,
            learnerName: '',
            completionStatus: 'unknown',
            suspendData: {},
            scormVersion: ''
          };
    }

    LMSInitialize() {
        // Initialisez l'API
        const scorm = SCORM.init();
        console.log('SCORM API initialized',scorm);
        this.state["apiConnected"] = scorm;
        return 'true';
    }

    LMSFinish() {
        // Nettoyez les ressources ici
        console.log('SCORM API finished');
        return 'true';
    }

    LMSSetValue(key, value) {
        // Enregistrez les valeurs de l'apprenant
        this.state[key] = value;
        console.log(`Set ${key} to ${value}`);
        return 'true';
    }

    LMSGetValue(key) {
        // Récupérez les valeurs de l'apprenant
        const value = this.state[key] || '';
        console.log(`Get ${key}: ${value}`);
        return value;
    }

    LMSCommit() {
        // Sauvegardez les données sur le serveur si nécessaire
        console.log('SCORM data committed');
        return 'true';
    }
}

// Assignez l'instance à `window.API`
window.API = new ScormAPI();
export default ScormAPI;