import React, { useEffect, useState } from "react";
import NavbarVertical from "../../components/NavbarVertical";
import HeaderDashboard from "../../components/HeaderDashboard";
import NavbarDashboardMobile from "../../components/NavbarDashboardMobile";
import MessageAllPage from "../../../backend/components/MessageAllPage";
import "./Presentation.css";
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import api from "../../../http/global-vars";

const Presentation = () => {
  const { state } = useLocation();
  const [item, setItem] = useState(state);
  const history = useHistory();
  const { id } = useParams();
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then((res) => {
        setItem(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };

  useEffect(() => {
    if (id) {
      getModule(id);
    } else {
      history.goBack()
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      setItem(state);
    }
  }, [state]);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="container-presentation-page mb-4">
              <div className="presenation-page-content">
                <h3 className="title-presentation-page-content mt-4 pb-4">
                  Bienvenue !
                </h3>
                <p className="text-presentation-page">
                  Avant de plonger dans les modules, regarde la présentation des
                  quatre personnages qui vont t'accompagner tout au long des
                  modules.{" "}
                </p>
                <p className="title-text-presentation-page py-4">
                  Quel est le personnage qui te correspond le plus ?
                </p>
                <p className="text-presentation-page">
                  A la fin de la présentation, choisis le personnage avec lequel
                  tu as le plus de points en commun.
                </p>
              </div>
            </div>
            <div
              className="container-btn-theme-presenttion-page"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <NavLink
                to={{
                  pathname: `/presention-personnages/${id}`,
                  state: item,
                }}
                className="btn btn-theme"
              >
                Commencer
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default Presentation;
