/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { withScorm } from "react-scorm-provider";
import { SCORM, debug } from "pipwerks-scorm-api-wrapper";
import ScormAPI from "../../utils/ScormAPI";
import scorm from "../../utils/Scorm";

function ScormContentAdmin(props) {
  const { module } = props;
  const iframeRef = useRef(null);

  useEffect(() => {
    // console.log("Initializing SCORM API");
    window.API = new ScormAPI();

    fetchCourses();
    // console.log("Course Progression:", courseProgress);
    const iframe = document.getElementById("targetIframe");
    iframe.onload = () => {
      console.log("SCORM API initialized:", window.API);
    };
  }, []);

  const fetchCourses = async () => {
    const SCORM_CLOUD_BASE_URL = "https://cloud.scorm.com/api/v2/";
    console.log("scorm", scorm);
    try {
      // // const courses = await scorm.getCourseList();
      // await scorm.getCourseList(function (error, result) {
      //   console.log(result);
      // });
      // console.log("courses scorm cloude",scorm);
      const url = `${SCORM_CLOUD_BASE_URL}course/list`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SCORM_SECRET_KEY}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  return (
    <div className="card-item-sessions-module contenu-shadow">
      <span className="link-collapse-session-item ">{module?.name}</span>
      <div className="card card-body p-t-50 m-b-0">
        <div className="row dis-flex justify-content-center">
          <div className="col-md-12 offset-md-0">
            <div class="scorm-container">
              <iframe
                src={module?.scorm_link}
                frameBorder="0"
                ref={iframeRef}
                title={module?.name}
                className="scorm-content"
                loading="lazy"
                id="targetIframe"
                // onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withScorm()(ScormContentAdmin);
